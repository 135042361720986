import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PasswordRequirement, PasswordService } from 'src/app/auth/services/password.service';

@Component({
  selector: 'sync-complex-password',
  templateUrl: './complex-password.component.html',
  styleUrls: ['./complex-password.component.css']
})
export class ComplexPasswordComponent implements OnInit, OnChanges {
  @Input() password: string;

  public passwordStrength = {
    label: '',
    color: '',
    value: 0,
  };

  public requirements: PasswordRequirement[] = [];
  private passwordScore = 0;
  public show = false;

  constructor(
    private passwordService: PasswordService,
  ) {}

  ngOnInit() {
    this.update();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['password']) {
      if (this.password) {
        this.show = true;
      }
      this.update();
    }
  }

  private update() {
    this.updatePasswordScore();
    this.updatePasswordStrength();
    this.updateRequirements();
  }

  private updatePasswordScore() {
    this.passwordScore = this.passwordService.passwordScore(this.password || '');
  }

  private updateRequirements() {
    this.requirements = this.passwordService.checkPasswordRequirements(this.password || '', this.passwordScore);
  }

  private updatePasswordStrength() {
    const score = this.passwordScore;
    if (!this.password || score < 0 || score > 4) {
      this.passwordStrength = {
        color: '#A2A2A2',
        label: 'incomplete',
        value: 0,
      };
    } else if (score === 0 || score === 1) {
      this.passwordStrength = {
        color: '#E23119',
        label: 'poor',
        value: 25,
      };
    } else if (score === 2) {
      this.passwordStrength = {
        color: '#F4BF1D',
        label: 'fair',
        value: 50,
      };
    } else if (score === 3) {
      this.passwordStrength = {
        color: '#83CFFF',
        label: 'good',
        value: 75,
      };
    } else if (score === 4) {
      this.passwordStrength = {
        color: '#64C038',
        label: 'excellent',
        value: 100,
      };
    }
  }
}
