import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ApiService } from '../../core/api.service';
import { ErrCode, GetUserKeysApiOutput } from '../../shared/models';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/user.service';
import { GetuserpubkeyApiOutput } from '../../shared/models/api/getuserpubkey-api.model';
import * as zxcvbn from 'zxcvbn';

export interface PasswordRequirement {
    description: string;
    passed: boolean;
}
@Injectable({
    providedIn: 'root'
})
export class PasswordService {
    private bCrypt: bCrypt;

    constructor(
      private api: ApiService,
      private auth: AuthService,
      private crypt: SyncCryptService,
      private log: LoggerService,
      private user: UserService
    ) {
        this.bCrypt = new bCrypt();
    }

    public async passwordValid(username: string, password: string): Promise<sync.IUserKeyInput> {
        const hashPass = await this.auth.getHashedPassword(username, password);
        try {
            await this.api.send('userverifypassword', {
                username: username,
                password: hashPass
            });

            const keyData = await this.api.send<GetUserKeysApiOutput>('getuserkeys', {
                username: username,
                password: hashPass
            });
            return keyData;
        } catch (e) {
            throw new ErrCode(8101);
        }
    }


    /**
     * RSA encrypts their new password with the Sync.com pubkey.
     * @param {string} code the code the user enters
     * @param {string} cachekey the cachekey in the URL
     * @param {string} passwordPlain their new plain text password
     * @returns {Promise}
     */
    public async resetPasswordMultiUser(childUserId: number, parentPassword: string, passwordPlain: string) {
        const pubkey = await this.api.fetchText('/key/password-recover/pubkey');
        const hashPass = await this.auth.hashPassword(this.bCrypt.gensalt(8), passwordPlain);
        const passResetKeys = await this.crypt.prepareResetPasswords(passwordPlain, pubkey);
        const pubkeyData = await this.api.execute<GetuserpubkeyApiOutput>('getuserpubkey', {
            target_user_id: childUserId
        });
        const encPassword = await this.crypt.userpasswordEncrypt(passwordPlain, pubkeyData.pub_key);

        const parentPass = await this.auth.getHashedPassword(this.user.getUser().email, parentPassword);

        return await this.api.execute('multiuserpasswordreset', {
            enc_pass_meta: passResetKeys.encPassMeta,
            salt_meta: passResetKeys.metaSalt,
            enc_pass_priv: passResetKeys.encPassPriv,
            salt_priv: passResetKeys.privSalt,
            password_hash: hashPass,
            enc_password: encPassword,
            child_user_id: childUserId,
            parent_password: parentPass
        });
    }

    public async disablePassReset() {
        return await this.api.execute('userinfoset', {
            passreset: { enable_reset: 0 }
        });
    }

    public passwordScore(pwd: string) {
        return zxcvbn(pwd || '').score;
    }

    public checkPasswordRequirements(pwd: string, score: number): PasswordRequirement[] {
        return [
            // {
            //     description: 'Uses a mix of upper and lower case letters',
            //     passed: /[a-z]/.test(pwd) && /[A-Z]/.test(pwd)
            // },
            {
                description: 'Contains 8 to 72 characters',
                passed: pwd.length >= 8 && pwd.length <= 72
            },
            // {
            //     description: 'Has at least 1 number and 1 special character',
            //     passed: /[0-9]/.test(pwd) && /[^a-zA-Z0-9]/.test(pwd)
            // },
            {
                description: 'Fair strength or better (use upper / lower / special characters)',
                passed: score >= 2
            },
        ];
    }

    public isPasswordStrong(pwd: string): boolean {
        return this.checkPasswordRequirements(pwd, this.passwordScore(pwd)).every(requirement => requirement.passed);
    }

    public async enablePassReset(plainPass: string, keyData: sync.IUserKeyInput) {
        const pubkey = await this.api.fetchText('/key/password-recover/pubkey');
        const encPassMeta = await this.crypt.prepareResetKey(keyData.enc_meta_key, plainPass, pubkey);
        const encPassPriv = await this.crypt.prepareResetKey(keyData.enc_priv_key, plainPass, pubkey);

        return await this.api.execute('userinfoset', {
            passreset: {
                enable_reset: 1,
                enc_pass_meta: encPassMeta,
                enc_pass_priv: encPassPriv
            }
        });
    }

    /**
     * Changes a password for an authenticated person. rejects if an error occurs
     * @returns {Promise}
     * commented out this method as it is not being used anywhere
     */
    // public async changePassword(passwordPlain: string) {

    //     const hashPass = await this.auth.hashPassword(this.bCrypt.gensalt(8), passwordPlain);
    //     const meta = await this.crypt.storeDecrypt('meta_key');
    //     const priv = await this.crypt.storeDecrypt('private_key');

    //     const encMeta = await this.crypt.userkeyEncrypt(meta, passwordPlain);
    //     const encPriv = await this.crypt.userkeyEncrypt(priv, passwordPlain);
    //     const encPass = await this.crypt.userpasswordEncrypt(passwordPlain, this.user.getUser().pubkey);

    //     return await this.api.execute('changepassword', {
    //         userpass: hashPass,
    //         enc_password_b64: encPass,
    //         enc_meta_key_b64: encMeta,
    //         enc_priv_key_b64: encPriv
    //     });
    // }
}
