import { Injectable } from '@angular/core';
import * as blend from 'mixpanel-browser';
import { DefaultProperties } from '../models';
import { environment } from '../../../environments/environment';
import { LoggerService } from 'src/app/core/logger.service';
import { isUrlHistoryClean, linkURLSanitizer } from 'src/app/util/blend-helper';

@Injectable({
    providedIn: 'root'
})
export class UnauthBlendService {

    private blend: any;

    constructor(
        private log: LoggerService,
    ) {
        this.blend = blend.init(
            environment.unauthBlendToken,
            {
                'persistence': 'localStorage', //This prevents getting a "Cookie Too Large" error and in general is a more reliable way to persist state on the browser.
                'api_host': environment.blendHost,
                ...(!environment.production && { 'debug': true })
            },
            'Sync — unauthenticated'
        );
    }

    getDefaultProperties(): DefaultProperties {
        if (!isUrlHistoryClean()) {
            const defaultProperties: DefaultProperties = {
                'current_page_title': '-',
                'current_url_path': '-',
                'current_url_search': '-',
                '$current_url': linkURLSanitizer(window.location.href), // Sanitize the URL if it's a link
                'platform': 'CP',
                '$referrer': linkURLSanitizer(document.referrer),
            };
            return defaultProperties;
        }
        const defaultProperties: DefaultProperties = {
            'current_url_path': window.location.pathname,
            'platform': 'CP'
        };
        return defaultProperties;
    }


    async trackPageview(): Promise<void> {
        this.blend.track_pageview(this.getDefaultProperties());
    }
}
