import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../core/notifications.service';
import { FeedbackService } from '../feedback.service';
@Component({
    selector: 'sync-notifications',
    templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {
    public notifications: any;
    public feedbackView: any;

    constructor(
        private feedbackService: FeedbackService,
        private notificationsService: NotificationsService,
    ) { }

    ngOnInit() {
        this.notifications = this.notificationsService.settings;
        this.feedbackView = this.feedbackService.view;
        this.notificationsService.startNotificationLoop();
    }

    public restartNotifications() {
        return this.notificationsService.startNotificationLoop();
    }

    public closeFeedback() {
        return this.feedbackService.hideFeedback();
    }
}
