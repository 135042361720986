import { LoggerService } from '../core/logger.service';

const log = new LoggerService();

/**
 * Checks if the URL history is clean by verifying that neither the referrer
 * nor the current URL contains restricted paths.
 * @returns {boolean} Returns true if no restricted paths are found
 */
export const isUrlHistoryClean = function () {
    const pathsToCheck = ['/dl/', '/signup-share', '/setuserinviteconsent'];

    const hasMatchingPath = (pathname) =>
        pathsToCheck.some((path) => pathname.startsWith(path));

    // Use window.location.pathname directly for current URL
    // and create new URL object for referrer each time
    const referrerPathname = document.referrer
        ? new URL(document.referrer, window.location.origin).pathname
        : '';

    return (
        !hasMatchingPath(window.location.pathname) &&
        !hasMatchingPath(referrerPathname)
    );
};

export const linkURLSanitizer = function (url) {
    try {
        // Parse the URL
        const parsedUrl = new URL(url);

        // Extract pathname and split using '/' or '?' as separators
        const pathParts = parsedUrl.pathname
            .split(/[\/\?#]/)
            .filter((part) => part !== '');
        const rootPath = pathParts.length ? pathParts[0] : '';

        switch (rootPath) {
            case 'dl': // e.g., /dl/cachekey#xxx-xxx-xx-xxx
                parsedUrl.pathname = '/' + pathParts.slice(0, 2).join('/');
                break;
            case 'signup-share': // share-invite non-members
                parsedUrl.pathname = '/' + pathParts.slice(0, 1).join('/');
                break;
            case 'setuserinviteconsent': // teams+ invites
                parsedUrl.pathname = '/' + pathParts.slice(0, 1).join('/');
                break;
        }

        return parsedUrl.origin + parsedUrl.pathname;
    } catch (error) {
        log.error('Error processing URL: ' + error.message);
        return url; // Return the original URL if there's an error
    }
};
