<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title">Add user</h4>
</div>
<div class="modal-body" *ngIf="openState == 1">
    <div class="row">
        <div class="col-sm-4">What would you like to do?</div>
        <div class="col-sm-8">
            <div class="radio radio-bottom" *ngIf="hasEmptySlots">
                <label>
                    <input
                        type="radio"
                        name="addAction"
                        id="multiuser-add-action-new"
                        value="new"
                        [(ngModel)]="addAction"
                    />
                    I'd like to create a new Sync user<br>
                    <span class="subtext">Use this option if the user doesn't have a Sync account.</span>
                </label>
            </div>
            <hr  *ngIf="hasEmptySlots">
            <div class="radio radio-top"  *ngIf="hasEmptySlots">
                <label>
                    <input
                        type="radio"
                        name="addAction"
                        id="multiuser-add-action-existing"
                        value="existing"
                        [(ngModel)]="addAction"
                    />
                    I'd like to add an existing Sync user<br>
                    <span class="subtext">Use this option if the user already has a Sync account.</span>
                </label>
            </div>
            <hr *ngIf="hasEmptySlots">
            <div class="radio radio-bottom">
                <label>
                    <input
                        type="radio"
                        name="addAction"
                        id="multiuser-add-action-order"
                        value="order"
                        [(ngModel)]="addAction"
                    />
                    I'd like to add additional users to my account<br>
                    <span class="subtext">Use this option to increase your user limit.</span>
                </label>
            </div>
        </div>
    </div>
</div>


<div class="modal-body" *ngIf="openState == 2">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-4">Username:</div>
        <div class="col-sm-8">
            <input
                type="email"
                class="form-control"
                value=""
                id="multiuser-add-email"
                (blur)="showInputError.email = true"
                [(ngModel)]="email"
                placeholder="Enter their email address">
                <div *ngIf="showInputError.email" >
                    <div 
                        *ngFor="let errorMsg of getValidationError().email" 
                        class="text-danger py-1">
                        {{errorMsg}}
                    </div>
                </div>
        </div>
    </div>
    <hr>

    <div class="row" *ngIf="addAction == 'existing'">
        <div class="col-sm-4">Ref code:</div>
        <div class="col-sm-8">
            <input
                type="text"
                class="form-control"
                id="multiuser-add-refcode"
                value=""
                [(ngModel)]="refcode"
                (blur)="showInputError.refCode = true"
                placeholder="Enter ref code">
            <div class="subtext">
                <a
                    href="https://www.sync.com/help/how-do-i-find-the-ref-code/"
                    class="syncblue"
                    target="_blank"
                    rel="noopener noreferrer"
                >(how do I find the ref code?)</a>
            </div>
            <div *ngIf="showInputError.refCode" >
                <div 
                    *ngFor="let errorMsg of getValidationError().refcode" 
                    class="text-danger py-1">
                    {{errorMsg}}
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="addAction == 'new'">
        <div class="row">
            <div class="col-sm-4">Password:</div>
            <div class="col-sm-8">
                <div>
                    <input
                        type="password"
                        class="form-control"
                        id="multiuser-add-password"
                        [(ngModel)]="password"
                        value=""
                        (blur)="showInputError.password = true"
                        placeholder="Enter their password"
                        syncShowHidePassword
                        identity="luseraddpass">
                </div>
                <sync-complex-password [password]="password"></sync-complex-password>
                <!-- <div *ngIf="showInputError.password" >
                    <div 
                        *ngFor="let errorMsg of getValidationError().password" 
                        class="text-danger py-1">
                        {{errorMsg}}
                    </div>
                </div> -->
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-4">Password reset:</div>
            <div class="col-sm-8">
                <div class="radio radio-top">
                    <label>
                        <input
                            type="radio"
                            name="passreset"
                            id="multiuser-add-passreset-enable"
                            value="1"
                            [(ngModel)]="passreset"
                        >
                        Allow Admininstrator (you) to reset password
                    </label>
                </div>
                <div class="radio radio-bottom">
                    <label>
                        <input
                            type="radio"
                            name="passreset"
                            id="multiuser-add-passreset-disable"
                            value="0"
                            [(ngModel)]="passreset"
                        >
                        Disabled (can only be enabled by user)
                    </label>
                </div>
            </div>
        </div>
    </ng-container>

    <hr>

    <div class="row">
        <div class="col-sm-4">Purge files:</div>
        <div class="col-sm-8">
            <div class="radio radio-top">
                <label>
                    <input
                        type="radio"
                        name="purgefiles"
                        id="multiuser-add-purgefiles-disable"
                        value="1"
                        [(ngModel)]="purgefiles"
                    >
                    Do not allow user to permanently delete files
                </label>
            </div>
            <div class="radio radio-bottom">
                <label>
                    <input
                        type="radio"
                        name="purgefiles"
                        id="multiuser-add-purgefiles-enable"
                        value="0"
                        [(ngModel)]="purgefiles"
                    >
                    Allow user to permanently delete files
                </label>
            </div>
        </div>
    </div>

    <hr>

    <div class="row">
        <div class="col-sm-12">
<span *ngIf="addAction == 'new'"> User will be sent an email verification to activate their account. You will also need to provide the user with the password you created, or instruct the user to issue a password reset (if enabled), to create their own password. Billing and upgrade options for the user will be managed exclusively under your account.</span>

<span *ngIf="addAction != 'new'"> User will be upgraded, and billing and upgrade options for the user will be managed exclusively under your account (these options will no longer be available for the user).</span>
        </div>
    </div>
</div>


<div class="modal-body" *ngIf="openState == 3">
        <sync-error [errcode]="errcode"></sync-error>
        <div class="row">
        <div class="col-sm-12">
            <p class="help-block black" style="padding-top:10px;">Select number of additional users to add:</p>

            <select class="form-control" style="width:150px;" [(ngModel)]="addUserAmount">
                <option [value]="i" *ngFor="let i of userLimit">{{i}}</option>
            </select>
        </div>
    </div>
</div>


<div class="modal-footer">
    <button
        *ngIf="openState == 1"
        type="button"
        class="btn btn-primary"
        [disabled]="!addAction"
        (click)="goNext()"
    >Next</button>

    <button
        *ngIf="openState != 1"
        [disabled]="(openState == 2 && !isInputValid()) || spinner"
        type="button"
        class="btn btn-primary"
        (click)="addUser()"
    >
        <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
        Add user
    </button>
</div>