import { Injectable } from '@angular/core';
import { LoggerService } from '../logger.service';
import { ErrCode } from '../../shared/models';
import * as sjcl from 'sjcl';
import { CryptMethodService } from '../../shared/models/crypt-method-service.model';



/* tslint:disable:no-bitwise */

@Injectable()
export class CryptLegacyService  implements CryptMethodService {

    constructor(private Logger: LoggerService) {
        sjcl.random.startCollectors();
        const  ArrayView = new Uint32Array(32);
        window.crypto.getRandomValues(ArrayView);
        // the sjcl addEntropy function is mislabeld in the npm @types/sjcl
        (<any>sjcl.random).addEntropy(ArrayView, 1024, 'crypto.getRandomValues');

    }

    public bytesToB64(bits: Array<number>): string {
        return sjcl.codec.base64.fromBits(bits);
    }
    public bytesToString(bits: Array<number>): string {
        return sjcl.codec.utf8String.fromBits(bits);
    }
    public bytesToHex(bits: Array<number>): string {
        return sjcl.codec.hex.fromBits(bits);
    }

    public hexToBytes(hex: string): Array<number> {
        return sjcl.codec.hex.toBits(hex);
    }
    public stringToBytes(str: string): Array<number> {
        return sjcl.codec.utf8String.toBits(str);
    }
    public b64ToBytes(b64: string): Array<number> {
        return sjcl.codec.base64.toBits(b64);
    }



    /**
     * @ngdoc method
     * @name  symmetricEncrypt
     * @methodOf sync.service:CryptMethodLegacy
     * @description
     * Encrypts data using symmetric encryption via sjcl
     * @param  {Array} key    The AES key
     * @param  {Array} plain  [description]
     * @param  {Array} iv     [description]
     * @param  {Array|null} header [description]
     * @return {Promise}        [description]
     */
    public symmetricEncrypt(key: Array<number>, plain: Array<number>, iv: Array<number>, header?: Array<number>): Promise<Array<number>> {
        header = header || [];
        try {
            const aes = new sjcl.cipher.aes(key),
                data = sjcl.mode.gcm.encrypt(aes, plain, iv, header, 96);
            return Promise.resolve([].concat(header).concat(iv).concat(data));
        } catch (ex) {
            this.Logger.e('CryptLegacy symmetric encrypt failed', ex);
            throw new ErrCode(2050);
       }
    }


    /**
     * Encrypts data using symmetric encryption via asmCrypto
     * @param  {Array} key    The AES key
     * @param  {Array} crypted  [description]
     * @param  {Array} iv     [description]
     * @param  {Array|null} header [description]
     * @return {Promise}        [description]
     */
    public symmetricDecrypt(key: Array<number>, crypted: Array<number>, iv: Array<number>, header?: Array<number>): Promise<Array<number>> {
        try {
            header = header || [];
            const aes = new sjcl.cipher.aes(key);
            return Promise.resolve(sjcl.mode.gcm.decrypt(aes, crypted, iv, header, 96));
        } catch (ex) {
           this.Logger.e('SyncCryptLegacy.symmetricDecrypt() failed', ex);
           throw new ErrCode(2150);
        }
    }

   /**
    * Decrypts data using a string password that is keystretched
    * @param  {String} b64_crypted A num-prefixed b64 string
    * @param  {String|Array} password    The AES key
    * @param  {Integer} iterations  iterations for keystretch
    * @return {Promise}        [description]
    */
    public passwordDecrypt(b64_crypted: string, password: string, iterations: number): Promise<string> {
       if (b64_crypted === '') {
           throw new Error('empty passwd encrypted string');
       } else if (b64_crypted.substring(0, 3) === '30:') {
            const raw = sjcl.codec.base64.toBits(b64_crypted.substring(3)),
                salt = raw.splice(0, 3),
                iv = raw.splice(0, 3),
                key = sjcl.misc.pbkdf2(password, salt, iterations, 32 * 8, sjcl.misc.hmac),
                aes = new sjcl.cipher.aes(key);
            return Promise.resolve(sjcl.codec.utf8String.fromBits(
                sjcl.mode.gcm.decrypt(aes, raw, iv, [], 96)
            ));
        } else {
            this.Logger.error('SyncCryptLegacy.passwordDecrypt() failed');
            this.Logger.error('Badly formatted password');
            throw new Error('badly formatted passwd encrypted string ' + b64_crypted);
        }
    }


    /**
     * Encrypts data using a string password that is keystretched
     * @param  {String} plain A num-prefixed b64 string
     * @param  {String|Array} password    The AES key
     * @param  {Integer} iterations  iterations for keystretch
     * @return {Promise}
     */
    public async passwordEncrypt(plain: string, password: string, iterations: number): Promise<string> {
        const raw = sjcl.codec.utf8String.toBits(plain),
            salt = this.getRandom(3 * 32);
        try {
            const key = await this.keyStretch(password, salt, iterations, 32 * 8);
            const aes = new sjcl.cipher.aes(key),
                iv = this.getRandom(3 * 32),
                enc = sjcl.mode.gcm.encrypt(aes, raw, iv, [], 96);
            return Promise.resolve('30:' + sjcl.codec.base64.fromBits(salt.concat(iv).concat(enc)));
        } catch (ex) {
            this.Logger.error('SyncCryptLegacy.passwordEncrypt() failed');
            this.Logger.e('Keystretch failed', ex);
            throw new Error('Error password encrypt');
        }
    }

    /**
     * Asymmetrically encrypts plain.  Plain should be a string, and in
     * most cases the string is base64, but it doesn't have to be.
     *
     * This method uses PidCrypt for RSA.  Unfortunately PidCrypt's RSA
     * will generate different string lengths for the encrypted data due to
     * padding. This method will loop until the encryption produces a string
     * with the length of 344 characters.
     *
     * @param  {String} pubkey The pubkey should be joined with '%' replacing
     *                         new line chars.
     * @param  {String} plain  Plain string
     * @return {Promise}        [description]
     */
    public asymmetricEncrypt(pubkey: string, plain: string): Promise<string> {
        let i = 0,
            crypted = this.rsaEncrypt(pubkey, plain);

        // unfortunately PidCrypt's RSA function produces different lengths
        // for RSA encryption (padding).  Loop through until we get one
        // at 344 length so that the PC Agent doesn't have trouble.
        while (crypted.length !== 344) {
            i += 1;
            crypted = this.rsaEncrypt(pubkey, plain);
            if (i >= 20) {
                this.Logger.error('An infinite loop occurred attempting to enc a share key');
                throw new Error('Error RSA encrypting');
            }
        }
        return Promise.resolve(crypted);
    }


    /**
     * Decrypts a crypted string with RSA.  Uses PidCrypt's RSA functions.
     * @param  {String} privkey In PEM format with new lines replaced with
     *                          '%' signs.
     * @param  {String} crypted Base64 string
     * @return {Promise}        [description]
     */
    public asymmetricDecrypt(privkey: string, crypted: string): Promise<string> {
        try {
            if (!privkey) {
                throw new ErrCode(2006);
            }

            const cert = this.cleanCertificate(privkey);
            const rsa = new pidCrypt.RSA(),
                key = pidCryptUtil.decodeBase64(cert),
                asn = pidCrypt.ASN1.decode(pidCryptUtil.toByteArray(key)),
                tree = asn.toHexTree();
            const data = pidCryptUtil.decodeBase64(pidCryptUtil.stripLineFeeds(crypted));
            rsa.setPrivateKeyFromASN(tree);

            const ret = rsa.decryptRaw(pidCryptUtil.convertToHex(data));

            if (ret == undefined || ret.length == 0) {
                throw new ErrCode(2024);
            }
            return Promise.resolve(ret);
        } catch (ex) {
            // if errcode 2024 when inviting share, it typically means the enc_password
            // field is invalid.
            this.Logger.e('Error in asymmetric decrypt for ' + crypted, ex);
            this.Logger.error('SyncCryptLegacy.asymmetricDecrypt() failed');
            throw ex;
        }
    }

    /**
    * @ngdoc method
    * @name  keyStretch
    * @methodOf sync.service:SyncCryptBuffer
    * Stretches data quickly
    * @todo Combine this with keystretch and use the iteration size
    *       to determine which method to use.
    * @param  {String|Array} password   [description]
    * @param  {Array} salt       [description]
    * @param  {Integer} iterations [description]
    * @param  {Integer} length     [description]
    * @return {Promise}            [description]
    */
    public keyStretch(password: string, salt: Array<number>, iterations: number, length: number): Promise<Array<number>> {
        return Promise.resolve(sjcl.misc.pbkdf2(password,
                                        salt,
                                        iterations || 60000,
                                        length
        ));
    }


    /**
    * @ngdoc method
    * @name  keyStretchSlow
    * @methodOf sync.service:SyncCryptLegacy
    * Stretches data with a higher iteration count.  Using SJCL it calls
    * publicApi.keyStretch instead.  The name is bound for compatibility
    * when called from SyncCryptFactory.
    * @todo Combine this with keystretch and use the iteration size
    *       to determine which method to use.
    * @param  {String|Array} password   [description]
    * @param  {Array} salt       [description]
    * @param  {Integer} iterations [description]
    * @param  {Integer} length     [description]
    * @return {Promise}            [description]
    */
    public keyStretchSlow(password: string, salt: Array<number>, iterations: number, length: number): Promise<Array<number>> {
        return this.keyStretch(password, salt, iterations, length);
    }

    /**
    * @ngdoc method
    * @name  getApiHmac
    * @methodOf sync.service:SyncCryptLegacy
    * @description
    * Gets a SHA1 HMAC of the data using key as secret
    * @param  {String|Array} key  [description]
    * @param  {Array} data [description]
    * @return {Promise}      [description]
    */
    public getApiHmac(key: Array<number>, data: Array<number>): Promise<Array<number>> {
        const hmac = new sjcl.misc.hmac(key, sjcl.hash.sha1);
        return Promise.resolve(hmac.mac(data));
    }


    /**
    * @ngdoc method
    * @name  getRandom
    * @methodOf sync.service:SyncCryptLegacy
    * @description
    * Gets random bits.
    * @param  {Integer} bits The bit amount
    * @return {Uint8Array}      [description]
    */
    public getRandom(bits: number): Array<number> {
        if (bits % 32 !== 0) {
            this.Logger.error('Bits is not modulus 32');
            throw new Error('getRandom bits not modules 32');
        }
        this.Logger.info('Get Random ' + bits + ' bits');
        if (sjcl.random.getProgress() < 1) {
            // if we attempt to get random words before we have enough
            //   seed generated, add some entropy
            this.Logger.info('Not enough seed, retrying');
            if (window && window.crypto && window.crypto.getRandomValues && Int32Array) {
                const ArrayView = new Int32Array(32);
                window.crypto.getRandomValues(ArrayView);
                sjcl.random.addEntropy(<Array<number>><ArrayLike<number>>ArrayView, 1024, 'crypto.getRandomValues');
            } else {
                sjcl.random.addEntropy(sjcl.codec.utf8String.toBits(Date.now().toString()), 1024, 'data');
            }
            return this.getRandom(bits);
        }
        return sjcl.random.randomWords(bits / 32);
    }

    /**
    * @ngdoc method
    * @name  getPartialBytes
    * @methodOf sync.service:SyncCryptLegacy
    * @description
    * Gets partial bits from the given data source.
    * @param  {Array} array     [description]
    * @param  {Integer} byteStart [description]
    * @param  {Integer} byteEnd   [description]
    * @return {Array}           [description]
    */
    public getPartialBytes(data: Array<number>, byteStart: number, byteEnd?: number): Array<number> {
        byteStart = byteStart / 32;
        byteEnd = (byteEnd) ? byteEnd / 32 : data.length;
        return data.slice(byteStart, byteEnd);
    }


    /**
    * @ngdoc method
    * @name  unpackHeader
    * @methodOf sync.service:SyncCryptLegacy
    * @description
    * Unpacks the header array and returns the encrypted offset stored within
    * @param  {Array} header     SJCL's bitarray
    * @return {Integer}           [description]
    */
    public unpackHeader(header: Array<number>): number {
        // make something that resembles a typical C byte array
        const a = [(sjcl.bitArray.extract(header, 0, 8)) , // #0
                (sjcl.bitArray.extract(header, 8, 8)) , // #1
                (sjcl.bitArray.extract(header, 16, 8)) , // #2
                (sjcl.bitArray.extract(header, 24, 8)) , // #3
                (sjcl.bitArray.extract(header, 32, 8)) , // #4
                (sjcl.bitArray.extract(header, 40, 8)) , // #5
                (sjcl.bitArray.extract(header, 48, 8)) , // #6
                (sjcl.bitArray.extract(header, 56, 8))   // #7
        ];
        let hi = (a[2] << 8) | (a[3] << 0);
        let lo = (a[4] << 24) | (a[5] << 16) | (a[6] << 8) | (a[7] << 0);

        // force to floating point
        hi = hi * 1.0;
        lo = lo * 1.0;

        return hi * (0xFFFFFFFF + 1) + lo;
    }

    /**
    * @ngdoc method
    * @name  unpackHeader
    * @methodOf sync.service:SyncCryptLegacy
    * @description
    * Packs a header for AES and embeds the offset in the first 8 bytes of the
    * header.  The number is encoded as an 8 byte array with the most significant
    * digit at index 0 and least significant at index 7.
    *
    * @param {int} offset the offsset.
    * @return {array} a byte array containing 3 32bit words.
    */
    public packHeader(offset: number): Array<number> {
        const hi = Math.floor(offset / 0xFFFFFFFF),
            lo = offset | 0x0;

        if (hi > 0xFF) {
            throw new Error('offset is too big (max = 2^40)');
        }

        const a = 0 |
            (hi & 0xFF000000) |
            (hi & 0x00FF0000) |
            (hi & 0x0000FF00) |
            (hi & 0x000000FF);

        const b = 0 |
            (lo & 0xFF000000) |
            (lo & 0x00FF0000) |
            (lo & 0x0000FF00) |
            (lo & 0x000000FF);
        return [a, b, 0];
    }


    public sha256hash(str: string): string {
        return sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(str));
    }

    public sha1hash(str: string): string {
        return sjcl.codec.hex.fromBits(sjcl.hash.sha1.hash(str));
    }

    /**
    * @ngdoc method
    * @name  sha1init
    * @methodOf sync.service:SyncCryptLegacy
    * @description
    * Initializes the SHA1 object
    * @return {sjcl.hash.sha1} [description]
    */
    public sha1init(): sjcl.SjclHash {
        return new sjcl.hash.sha1();
    }

    /**
    * @ngdoc method
    * @name  sha1reset
    * @methodOf sync.service:SyncCryptLegacy
    * @description
    * Updates a sha1 with more data
    * @param  {sjcl.hash.sha1} sha1Obj [description]
    * @return {sjcl.hash.sha1}         [description]
    */
    public sha1reset(sha1Obj: sjcl.SjclHash): sjcl.SjclHash {
        return sha1Obj.reset();
    }

    /**
    * @ngdoc method
    * @name  sha1update
    * @methodOf sync.service:SyncCryptLegacy
    * @description
    * Updates a sha1 with more data
    * @param  {sjcl.hash.sha1} sha1Obj [description]
    * @param  {Uint8Array} data    [description]
    * @return {asjcl.hash.sha1}         [description]
    */
    public sha1update(sha1Obj: sjcl.SjclHash, data: Array<number>): sjcl.SjclHash {
        return sha1Obj.update(data);
    }

    /**
    * @ngdoc method
    * @name  sha1finish
    * @methodOf sync.service:SyncCryptLegacy
    * @description
    * Finishes and gets the sha1 result
    * @param  {sjcl.hash.sha1} sha1Obj [description]
    * @return {Uint8Array}         [description]
    */
    public sha1finish(sha1Obj: sjcl.SjclHash): Array<number> {
        return sha1Obj.finalize();
    }



    /**
    * @ngdoc method
    * @name  arraybufferToBytes
    * @methodOf sync.service:SyncCryptLegacy
    * @description
    * This function doesn't do anything.  It's needed because legacy (sjcl)
    * requires it's own "bitArray" instead of array buffers.
    * @param  {ArrayBuffer} buffer [description]
    * @return {Array}               SJCL's bitArray
    */
    public arraybufferToBytes(buffer: ArrayBuffer): Array<number> {
        const bytes = new Uint8Array(buffer);
        return  sjcl.codec.bytes.toBits(<Array<number>><ArrayLike<number>>bytes);
    }

    /**
    * @ngdoc method
    * @name  filedataAppend
    * @methodOf sync.service:SyncCryptLegacy
    * @description
    * Appends payload with the appendedPayload bitArray
    * @param  {Array} appendedPayload [description]
    * @param  {number} maxLength ignored in this crypt method
    * @return {Array}                 [description]
    */
    public filedataAppend(appendedPayload: Array<number>[], _maxLength: number): Array<number> {
        let result = [];
        for (let i = 0; i < appendedPayload.length; i++) {
            result = result.concat(appendedPayload[i]);
        }
        return result;
    }


    /**
    * @ngdoc method
    * @name  prepareDataSend
    * @methodOf sync.service:SyncCryptBuffer
    * @description
    * Prepares data to be sent to MFS.  Due to 32bit integers, once placed
    * inside of a Blob object, the byte order swaps.
    * compatibility for Legacy.
    * @param  {Uint8Array} data [description]
    * @return {Uint8Array}      [description]
    */
    public prepareDataSend(data: Array<number>): ArrayLike<number> {
        return new Int32Array(this.swapByteOrder(data));
    }


    public checkBitLength(data: ArrayLike<number>, bits: number): boolean {
        return (data.length * 32 === bits);
    }

    /**
    * @ngdoc method
    * @name  swapByteOrder
    * @methodOf sync.service:SyncCryptLegacy
    * @description
    * Swaps the byte order for a byte array.
    *
    * When putting a byte array into and taking it out of a Javascript Typed
    * Array (e.x., Int32Array) the 32 bit word gets swapped.  This function
    * reverses the swap and should be called before putting a byterray into a
    * typed array and after reading from a typedarray.
    *
    * @param  {Array} bytearray - The byte array to swap.
    * @return {Array}           - The byte array after the swap.
    */
    private swapByteOrder(bytearray: Array<number>): Array<number> {
        const newarray = [];
        for (let i = 0, len = bytearray.length; i < len; i++) {
            newarray[i] = ((bytearray[i] & 0xFF) << 24) |
                ((bytearray[i] & 0xFF00) << 8) |
                ((bytearray[i] >> 8) & 0xFF00) |
                ((bytearray[i] >> 24) & 0xFF);
        }
        return newarray;
    }


    /**
    * Cleans a certificate and ensures it's formatted in the correct method.
    *
    * The certificiate must have all new line chars replaced with '%' signs
    *
    * @param  {String} privkey [description]
    * @return {String}         [description]
    */
    private cleanCertificate(privkey: string): string {
        privkey = privkey.split('%').join('\n');
        let cert = '', len, i;
        const lines = privkey.split('\n');
        for (i = 0, len = lines.length; i < len; i++) {
            switch (lines[i].substr(0, 9)) {
                case '-----BEGI': break;
                case '-----END ': break;
                default:
                    cert += lines[i].replace(/(\r\n|\n|\r)/gm, ' ');
            }
        }
        return cert;
    }

    /**
    * Encrypts data using RSA and returns the encrypted data as Base64
    * @param  {String} publickey [description]
    * @param  {String} plain     [description]
    * @return {String}           Base64 encrypted result.
    */
    private rsaEncrypt(publickey: string, plain: string): string {
        const rsa = new pidCrypt.RSA(),
            // pubkey = publickey.split('%').join('\n'),
            key = pidCryptUtil.decodeBase64(this.cleanCertificate(publickey)),
            asn = pidCrypt.ASN1.decode(pidCryptUtil.toByteArray(key)),
            tree = asn.toHexTree();
        rsa.setPublicKeyFromASN(tree);

        const crypted = rsa.encryptRaw(plain);
        return pidCryptUtil.fragment(
            pidCryptUtil.encodeBase64(
                pidCryptUtil.convertFromHex(crypted)
            ),
            64
        ).split('\n').join('');
    }
}
