import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FooterComponent } from './components/footer/footer.component';
import { HeaderUserComponent } from './components/header/user/header-user.component';
import { HeaderDefaultComponent } from './components/header/default/header-default.component';
import { HeaderLinksComponent } from './components/header/links/header-links.component';
import { HeaderPreviewsComponent } from './components/header/previews/header-previews.component';
import { HeaderNosignupComponent } from './components/header/nosignup/header-nosignup.component';
import { VerifyEmailRequiredComponent } from './components/verify-email-required/verify-email-required.component';
import { HeaderSharesAcceptComponent } from './components/header/sharesaccept/header-shares-accept.component';
import { ModalComponent} from './components/modal/modal.component';

import { BytesToSizePipe } from './pipes/bytes-to-size.pipe';
import { B64Pipe } from './pipes/b64.pipe';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ErrorComponent } from './components/error/error.component';
import { NgbDropdownModule, NgbModalModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentLengthDisplayComponent } from './components/content-length-display/content-length-display.component';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { StartFromPipe } from './pipes/start-from.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { UcFirstPipe } from './pipes/uc-first.pipe';
import { PathRenameFormComponent } from './components/path-rename-form/path-rename-form.component';
import { DisplayPaginationComponent } from './components/display-pagination/display-pagination.component';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { ShowFocusDirective } from './show-focus.directive';
import { TranslateTransferItemPipe } from './pipes/translate-transfer-item.pipe';
import { SaveFileComponent } from './components/save-file/save-file.component';
import { TrimDirective } from './directives/trim.directive';
import { HideToolsDirective } from './directives/hide-tools.directive';
import { DialogboxLinkDirective } from './directives/dialog-link.directive';
import { DialogTransferDirective } from './directives/dialog-transfer.directive';
import { PreventRightClickDirective } from './directives/prevent-right-click.directive';
import { DialogShareNewDirective } from './directives/dialog-share-new.directive';
import { CommentBlockComponent } from './components/comment/comment-block/comment-block.component';
import { CommentComponent } from './components/comment/comment/comment.component';
import { ReplyComponent } from './components/comment/reply/reply.component';
import { CommentCpIFrameComponent } from './components/comment/comment-cp-service/comment-cp-iframe.component';
import { UploadDropBoxDirective } from './directives/upload-drop-box.directive';
import { UploadPublicDropBoxDirective } from './directives/upload-public-drop-box.directive';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { AvatarStackComponent } from './components/avatar-stack/avatar-stack.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { IsFeatureAllowedDirective } from './directives/is-feature-allowed.directive';
import { SidePromptBoxComponent } from './components/side-prompt-box/side-prompt-box.component';
import { SyncShowHidePasswordDirective } from '../shared/directives/show-hide-password.directive';
import { ComplexPasswordComponent } from './components/complex-password/complex-password.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        //UIRouterUpgradeModule,
        NgbDropdownModule,
        NgbModalModule,
        NgbPopoverModule,
        // MatFormFieldModule,
        // MatButtonModule,
        // MatInputModule,
        // MatToolbarModule,
        // MatMenuModule
        NgbTooltipModule,
    ],
    exports: [
        // HeaderComponent,
        FooterComponent,
        StopPropagationDirective,
        HeaderUserComponent,
        HeaderDefaultComponent,
        HeaderLinksComponent,
        HeaderPreviewsComponent,
        HeaderNosignupComponent,
        ErrorComponent,
        VerifyEmailRequiredComponent,
        HeaderSharesAcceptComponent,
        // MailInviteComponent,
        BytesToSizePipe,
        B64Pipe,
        ContentLengthDisplayComponent,
        LimitToPipe,
        StartFromPipe,
        OrderByPipe,
        UcFirstPipe,
        PathRenameFormComponent,
        ShowFocusDirective,
        DisplayPaginationComponent,
        TranslateTransferItemPipe,
        SaveFileComponent,
        ModalComponent,
        TrimDirective,
        HideToolsDirective,
        DialogboxLinkDirective,
        DialogTransferDirective,
        PreventRightClickDirective,
        DialogShareNewDirective,
        CommentBlockComponent,
        CommentComponent,
        ReplyComponent,
        CommentCpIFrameComponent,
        UploadDropBoxDirective,
        UploadPublicDropBoxDirective,
        MultiSelectDropdownComponent,
        AvatarStackComponent,
        AvatarComponent,
        IsFeatureAllowedDirective,
        SidePromptBoxComponent,
        SyncShowHidePasswordDirective,
        ComplexPasswordComponent,
    ],
    declarations: [
        // HeaderComponent,
        FooterComponent,
        StopPropagationDirective,
        HeaderUserComponent,
        HeaderDefaultComponent,
        ShowFocusDirective,
        HeaderLinksComponent,
        HeaderPreviewsComponent,
        HeaderNosignupComponent,
        BytesToSizePipe,
        // MailInviteComponent,
        B64Pipe,
        ErrorComponent,
        VerifyEmailRequiredComponent,
        HeaderSharesAcceptComponent,
        ContentLengthDisplayComponent,
        LimitToPipe,
        StartFromPipe,
        OrderByPipe,
        UcFirstPipe,
        PathRenameFormComponent,
        DisplayPaginationComponent,
        TranslateTransferItemPipe,
        SaveFileComponent,
        ModalComponent,
        TrimDirective,
        HideToolsDirective,
        DialogboxLinkDirective,
        DialogTransferDirective,
        PreventRightClickDirective,
        DialogShareNewDirective,
        CommentBlockComponent,
        CommentComponent,
        ReplyComponent,
        CommentCpIFrameComponent,
        UploadDropBoxDirective,
        UploadPublicDropBoxDirective,
        MultiSelectDropdownComponent,
        AvatarStackComponent,
        AvatarComponent,
        IsFeatureAllowedDirective,
        SidePromptBoxComponent,
        SyncShowHidePasswordDirective,
        ComplexPasswordComponent,
    ],
    entryComponents: [
        ErrorComponent,
        HeaderDefaultComponent,
        HeaderUserComponent,
        HeaderPreviewsComponent,
        HeaderLinksComponent,
        HeaderNosignupComponent,
        FooterComponent,
        VerifyEmailRequiredComponent,
        HeaderSharesAcceptComponent,
        PathRenameFormComponent,
        DisplayPaginationComponent,
        SaveFileComponent,
        ModalComponent,
        AvatarStackComponent,
        AvatarComponent,
    ],
})
export class SharedModule {}
