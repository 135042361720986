import { Injectable } from '@angular/core';
import { LoggerService } from '../logger.service';
import { SyncCryptService } from './sync-crypt.service';
import { DirtyInTasks, DirtyInTaskItem, DirtyInTaskResult } from '../../shared/models';
import { ApiService } from '../api.service';

@Injectable()
export class DirtyInService {

    constructor(
        private api: ApiService,
        private log: LoggerService,
        private crypt: SyncCryptService
    ) { }

    public async processDirtyIn(worklist: DirtyInTasks) {
        const metalist: DirtyInTaskResult[] = [],
              items = worklist.items || [],
              sharekeys = worklist.sharekeys;

        for (let i = 0, len = items.length; i < len; i++) {
            const item = items[i];
            try {
                if (!sharekeys || !sharekeys[item.sharekey_id]) {
                    throw new Error('No share key found for ' + item.sharekey_id);
                }

                const sharekey = await this.crypt.sharekeyDecrypt(
                    sharekeys[item.sharekey_id],
                    item.sharekey_id
                );
                const plainName = await this.crypt.filenameDecrypt(
                    item.enc_share_name,
                    sharekey
                );
                const encName = await this.crypt.filenameEncrypt(plainName);

                const result = new DirtyInTaskResult();
                result.id = item.id;
                result.pid = item.pid;
                result.enc_name = encName;
                result.hist_id = item.hist_id;
                result.share_id = item.share_id;
                result.sharename_digest = item.sharename_digest;
                metalist.push(result);
            } catch (e) {
                this.log.e('An error occurred attempting to process dirty in', e);
                const msg = [
                    ' ID: ', item.id,
                    ' PID: ', item.pid,
                    ' SHAREID: ', item.share_id,
                    ' Sharekey ID: ' , item.sharekey_id].join('');
                this.log.error(msg);
                if (e instanceof Error) {
                    throw e;
                } else {
                    throw new Error(msg);
                }
            }
        }

        if (metalist.length) {
            await this.api.execute('pathsetmetanamelist', {
                list: metalist
            });
            return;
        }
        return;
    }
}


