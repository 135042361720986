import { BrowserModule } from '@angular/platform-browser';
import {
    NgModule,
    NgModuleFactoryLoader,
    SystemJsNgModuleLoader,
    APP_INITIALIZER,
    ErrorHandler
} from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { effects } from './effects';
import { AccountModule } from './account/account.module';
import { LinksModule } from './links/links.module';
import { SharedModule } from './shared/shared.module';
import { TransferModule } from './transfer/transfer.module';
import { environment, extModules } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { UtilModule } from './util/util-module';
import { SharesModule } from './shares/shares.module';
import { OpenDialogDirective } from './util/open-dialog.directive';
import { FileActionsModule } from './file-actions/file-actions-module';
import { BlendService } from './shared/services/blend.service';
import { NotificationsModule } from './notifications/notifications-module';
import { AppRoutingModule } from './app-routing.module';
import { DatePipe } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { CoreModule } from './core/core.module';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NewRoutesMapping } from './shared/models/new-route-mapping';
import { UserService } from './core/user.service';
import { UnauthBlendService } from './shared/services/unauthblend.service';
import { FeatureService } from './shared/services/feature.service';
import * as Sentry from '@sentry/angular';

export function windowFactory() {
    return window;
}
@NgModule({
    declarations: [AppComponent, OpenDialogDirective],
    imports: [
        BrowserModule,
        HttpClientModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        // Instrumentation must be imported after importing StoreModule
        extModules,
        EffectsModule.forRoot(effects),
        CoreModule,
        SharedModule,
        AppRoutingModule,
        LayoutModule,
        AccountModule,
        LinksModule,
        TransferModule,
        UtilModule,
        SharesModule,
        FileActionsModule,
        NotificationsModule,
    ],
    providers: [
        CookieService,
        DatePipe,
        // Provide the SystemJsNgModuleLoader when using Angular lazy loading
        { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },
        { provide: 'window', useFactory: windowFactory },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
          },
    ],
    bootstrap: [AppComponent], // commented out because we are using upgrade
})
export class AppModule {
    constructor(
        private router: Router,
        private blendService: BlendService,
        private userService: UserService,
        private featureService: FeatureService,
        private unauthBlendService: UnauthBlendService
    ) {
        this.router.events.subscribe(async (routeEvent) => {
            if (routeEvent instanceof NavigationStart) {
                let newRoute = NewRoutesMapping(routeEvent.url);
                if (routeEvent.url.includes('/files') || routeEvent.url.includes('/vault')) {
                    if (
                        await this.featureService.isAllowed(
                            'filesDirectoryPage'
                        )
                    ) {
                        newRoute = routeEvent.url;
                    } else {
                        newRoute = '';
                    }
                }
                if (routeEvent.url.startsWith('/multi-user/users') || routeEvent.url.startsWith('/multi-user/roles')) {
                    if (
                        !await this.featureService.isAllowed('newTeamsUsersPage')
                    ) {
                        newRoute = '';
                    }
                }
                if (routeEvent.url === '/multi-user') {
                    if (
                        !await this.featureService.isAllowed('newLegacyUsersPage')
                    ) {
                        newRoute = '';
                    }
                }
                if (routeEvent.url.includes('/recents')) {
                    if (
                        await this.featureService.isAllowed('newRecents')
                    ) {
                        newRoute = routeEvent.url;
                    } else {
                        newRoute = '';
                    }
                }
                if (routeEvent.url.includes('/starred')) {
                    if (
                        await this.featureService.isAllowed('newStarredPage')
                    ) {
                        newRoute = routeEvent.url;
                    } else {
                        newRoute = '';
                    }
                }
                if (routeEvent.url.includes('/shares')) {
                    if (await this.featureService.isAllowed('newSharesPage')) {
                        newRoute = routeEvent.url;
                    } else {
                        newRoute = '';
                    }
                }
                if (this.userService.get('is_newui_enabled') && newRoute) {
                    window.location.href = environment.syncCpHost + newRoute;
                }
            }
            if (routeEvent instanceof NavigationEnd) {
                window.scrollTo(0, 0);
                if (!this.userService.isAuthenticated()) {
                    // we only want to log 1% of all unauthenticated page views **unless** it's a shared folder invititation
                    const isShareInvite = [
                        '/shares',
                        '/setuserinviteconsent',
                        '/signup-share',
                    ].some((term) => routeEvent.url.includes(term));

                    if (isShareInvite) {
                        this.unauthBlendService.trackPageview();
                    } else {
                        const shouldLogPageView = Math.random() < 0.01;
                        if (shouldLogPageView) {
                            this.unauthBlendService.trackPageview();
                        }
                    }
                } else {
                    this.blendService.trackPageview();
                }
            }
        });
    }
}
