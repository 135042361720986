<ng-container *ngIf="show">
  <div class="password-strength">
      <span class="rating">
          <label>Password strength:</label>&nbsp;<span class="value">{{ passwordStrength.label }}</span>
      </span>
      <span class="bar">
          <span class="fill" [ngStyle]="{width:(passwordStrength.value)+ '%', 'background-color':passwordStrength.color}"></span>
      </span>
  </div>
  <div class="requirements">
      <label>Password requirements: <span class="hint fa fa-info-circle" ngbTooltip="Create a stronger password by making it longer, mixing lower and upper case letters, or adding numbers and special characters."></span></label>
      <div class="checklist">
        <div *ngFor="let req of requirements" class="check">
          <img [src]="req.passed ? 'images/checkmark.svg' : 'images/xmark.svg'" /> {{req.description}}
        </div>
      </div>
  </div>
</ng-container>
