<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
    <h4 class="modal-title">{{openState == 4 ? (remainingUsersAmount == 0 ? "You are out of limit for users" : "Invite user") : "Purchase more users"}}</h4>
</div>
<div class="modal-body" *ngIf="openState == 1">
    <div class="row">
        <div class="col-sm-4">What would you like to do?</div>
        <div class="col-sm-8">
            <div class="radio radio-bottom" *ngIf="hasEmptySlots">
                <label>
                    <input type="radio" name="addAction" id="multiuser-add-action-new" value="newusers"
                        [(ngModel)]="addAction" />
                    I'd like to add new Sync users<br>
                    <span class="subtext">Use this option if the users do not have Sync accounts.</span>
                </label>
            </div>
            <hr *ngIf="hasEmptySlots && this.checkPermission(permissionActions.MANAGE_BILLING)">
            <div class="radio radio-bottom" *ngIf="this.checkPermission(permissionActions.MANAGE_BILLING)">
                <label>
                    <input type="radio" name="addAction" id="multiuser-add-action-order" value="order"
                        [(ngModel)]="addAction" />
                    I'd like to add additional users to my account<br>
                    <span class="subtext">Use this option to increase your user limit.</span>
                </label>
            </div>
        </div>
    </div>
</div>

<div class="modal-body" *ngIf="openState == 3">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            <p class="help-block black" style="padding-top:10px;">Select number of additional users to purchase:</p>

            <select class="form-control" style="width:150px;" [(ngModel)]="addUserAmount">
                <option [value]="i" *ngFor="let i of userLimit">{{i}}</option>
            </select>
        </div>
    </div>
</div>

<div class="modal-body" *ngIf="openState == 5">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            <p class="help-block black" style="padding-top:10px;">The trial does not allow for adding additional users. If you like to add more users, please upgrade to a paid plan </p>
        </div>
    </div>
</div>

<div class="modal-body" *ngIf="openState == 6">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            <p class="help-block black" style="padding-top:10px;">The offer does not allow for adding additional users. If you like to add more users, please contact billing support.</p>
        </div>
    </div>
</div>

<div class="modal-body" *ngIf="openState == 4 && !isEmailVerified">
    <sync-verify-email-required></sync-verify-email-required>
</div>

<div class="modal-body" *ngIf="openState == 4 && isEmailVerified">
    <ng-container *ngIf="remainingUsersAmount != 0">
        <sync-error [errcode]="errcode"></sync-error>
        <div class="row">
            <div class="col-sm-12"><strong>Upload CSV:</strong></div>
            <div class="col-sm-12"><strong>Need help? </strong><a href="https://www.sync.com/help/getting-started-with-pro-teams-plus/#bproadduser" target="_blank">Download a CSV template</a>, and learn more.</div>
            <div class="col-sm-12" *ngIf="alertMsg && alertMsg.message">
                <div class="alert" [ngClass]="alertMsg.type == 'error' ? 'alert-danger' : 'alert-info'" role="alert" style="margin: 24px 0 0;">
                    {{alertMsg.message}}
                </div>    
            </div>
            <div class="col-sm-12" *ngIf="isSeatsNeeded">
                <div class="alert alert-danger" role="alert" style="margin: 24px 0 0;">
                    Error: you dont have enough seats. <a (click)="showPurchaseUsers()" class="hand">Purchase additional seats</a>
                </div>    
            </div>
        
            <div class="col-sm-12 file-upload">
                <div class="file-upload-inner" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
                    <ng-container *ngIf="!selectedFilename">
                        <div class="file-upload-msg" (onFileDropped)="uploadListener($event)">
                            Drag and Drop CSV file here
                            <br>
                            Or
                        </div>
                        <input class="btn btn-primary hidden" type="file" id="csvFile" (change)="uploadListener($event)" #csvReader
                        accept=".csv" />
                        <input class="btn btn-primary primary-outline" [disabled]="extraUsers" (click)="csvReader.click()" type="button"
                        value="{{ selectedFilename !== '' ? selectedFilename : 'Select a CSV file to upload' }}" />
                    </ng-container>
                    <div class="uploaded-file-container" *ngIf="selectedFilename" [ngClass]="{'alert-danger' : (alertMsg && alertMsg.type) == 'error'}">
                        <button type="button" class="close" (click)="fileReset()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="uploaded-file">
                            <div style="color: #000">{{selectedFilename}}</div>
                            <div *ngIf="(alertMsg && alertMsg.type) == 'error'">File Error</div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <hr />
        <div class="row" style="min-height: 90px;">
            <div class="col-sm-12">
                <strong>
                    OR enter emails:
                </strong>
            </div>
            <div class="col-sm-12">
                <div class="input-group">
                    <input
                        [(ngModel)]="emailField"
                        class="form-control"
                        [disabled]="extraUsers"
                        (keyup.enter)="addEmails()"
                        (blur)="addEmails()"
                        />
                    <div class="input-group-btn">
                        <button [disabled]="extraUsers" (click)="addEmails()" class="btn btn-primary">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>

                <div class="shareuserbadges" *ngIf="emails.length" style="padding-top: 10px;">
                    <span class="badge" *ngFor="let email of emails">
                        <span>{{email}}</span>
                        <i (click)="removeEmail(email)" style="margin-left: 3px;" class="fas fa-times"></i>
                    </span>
                </div>
        
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="remainingUsersAmount == 0">
        <div class="row">
            <div class="col-sm-12">
                <p class="help-block black" style="padding-top:10px;">
                    You have reached your limit of allotted users. Please purchase
                    additional users if you wish to continue inviting.
                </p>
            </div>
        </div>
    </ng-container>
</div>

<div class="modal-footer">
    <button *ngIf="openState == 1" type="button" class="btn btn-primary" [disabled]="!addAction"
        (click)="goNext()">Next</button>

    <button *ngIf="openState == 3" [disabled]="!inputValid() || spinner" type="button"
        class="btn btn-primary" (click)="addUser()">
        <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
        Purchase
    </button>
    <button *ngIf="openState == 4 && remainingUsersAmount == 0" type="button"
        class="btn btn-primary" (click)="showPurchaseUsers()">
        <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
        Next
    </button>

    <button *ngIf="openState != 1 && openState == 4 && remainingUsersAmount != 0 && isEmailVerified" type="button" [disabled]="spinner || isUserInvited" class="btn btn-primary"
        (click)="extraUsers ? proceedAddUser() : addUser()">
        <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
        {{ extraUsers ? "Proceed anyway" : "Invite" }}
    </button>
    <button *ngIf="openState != 1 && openState == 4 && extraUsers" type="button" class="btn btn-default"
        (click)="resetFields()">
        Reset
    </button>
    <button *ngIf="openState == 5" type="button"
        (click)="redirectToUpgrade()" class="btn btn-primary">
        Purchase plan
    </button>

    <button type="button" class="btn btn-default" *ngIf="openState == 4 && !isEmailVerified" (click)="activeModal.close()" aria-label="Cancel">Cancel</button>
</div>