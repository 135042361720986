<div class="container" style="padding-top:80px;">
    <div class="row">
        <div class="col-sm-12 header-box-withmenu" *ngIf="teamsSubUser">
            <div class="header-title">
                Contact your Administrator for billing inquiries.
            </div>
        </div>
        <div class="col-sm-12 header-box-withmenu" *ngIf="cancelInFuture">
            <div class="header-title" style="font-size: 30px;font-weight:600;">
                Your account is pending cancellation
            </div>
            <p>Please contact support if you wish to upgrade your plan.</p>
        </div>
        <div class="col-sm-12 header-box-withmenu" *ngIf="unpaidInvoices">
            <div class="header-title" style="font-size: 30px;font-weight:600;">
                Your account has unpaid invoices
            </div>
            <p>Please renew your account before making changes to your current plan. If you need further assistance,
                please contact support.</p>
            <p>
                <a href="/account/renew" class="btn btn-primary">Renew Now</a>
            </p>
        </div>

        <div class="header-title" *ngIf="user.disk_usage_bytes > user.disk_limit_bytes">
        </div>
        <div class="col-xs-12" style="margin-bottom:24px;" *ngIf="!cancelInFuture && !unpaidInvoices && !teamsSubUser">
            <div class="header-title" style="font-size: 30px;font-weight:600;"> {{user.is_on_trial ? "Sync Pro trial" :"Upgrade your Sync instantly"}} </div>
            <div style="font-size:18px;"><i class="fas fa-heart" style="color:#ef4034;"></i> 
                {{user.is_on_trial ? "Buy now risk free, always with a 30-day money back guarantee!" : "30-day money back guarantee!"}}
            </div>
            <div class="promo" *ngIf="upgradePromoHtml" [innerHTML]="upgradePromoHtml"></div>
            <div class="container" style="margin-top: 20px;" *ngIf="user.is_on_trial && currentTrialPlan">
                <div class="row">
                    <sync-plan-details [plan]="currentTrialPlan" [on-upgrade]="onUpgrade" [teams-qty]="(currentTrialPlan == teamsStandardPlan || currentTrialPlan == teamsUnlimited) ? teamsQty : 0" [user-limit]="userLimit"
                    [view-term]="viewTerm" [plan-expiry]="trialPlanExpiry" [is-trial]="user.is_on_trial" [isTrialUserWithoutCC]="isTrialUserWithoutCC" [currentTerm]="planterm"></sync-plan-details>
                </div>
            </div>
            <div *ngIf="user.is_on_trial && !spinner" style="margin-top: 20px;">
                <div style="font-size: 30px; font-weight: 600;">Make changes</div>
                <div style="font-size: 18px;">Loving the trial but need to make changes? Select a new plan or add users and click Change plan:</div>
            </div>
            <div class="upgrade-selections" *ngIf="products && (products.PER || products.PS) && isTeamsOnTrial">
                <div class="btn btn-primary" [class.btn-selected]="view == 'individual'" (click)="view = 'individual'"
                    style="font-size:18px;width:180px;margin:0px;border-top-right-radius: 0px;border-bottom-right-radius: 0px;">
                    For individuals</div>
                <div class="btn btn-primary" [class.btn-selected]="view == 'teams'" (click)="view = 'teams'"
                    style="font-size:18px;width:180px;margin:0px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;">
                    For teams</div>
            </div>
            <div class="row" style="padding-top:32px;" *ngIf="spinner">
                <div class="col-sm-12">
                    <i class="fa fa-spin fa-spinner"></i>
                    Processing user information ...
                </div>
            </div>
        </div>

    </div>
</div>
<!-- end header -->


<!-- start product selection row -->


<div class="container" *ngIf="!cancelInFuture && !unpaidInvoices && !teamsSubUser">
    <div class="row" *ngIf="!spinner">
        <sync-error [errcode]="errcode"></sync-error>
        <ng-container *ngIf="view == 'individual'">

            <div class="col-md-4" *ngIf="personalPlan.annual && personalPlan.annual.price">

                <sync-plan-details [plan]="personalPlan" [on-upgrade]="onUpgrade" [is-current-plan]="currentTrialPlan == personalPlan"></sync-plan-details>

            </div>


            <div  [ngClass]="personalPlan.annual && personalPlan.annual.price ? 'col-md-4' : 'col-md-6'" *ngIf="(soloBasicPlan.annual && soloBasicPlan.annual.price) || (soloBasicPlan.monthly && soloBasicPlan.monthly.price)">

                <sync-plan-details [plan]="soloBasicPlan" [on-upgrade]="onUpgrade" [view-term]="viewTerm" [is-current-plan]="currentTrialPlan == soloBasicPlan"></sync-plan-details>

            </div>


            <div [ngClass]="personalPlan.annual && personalPlan.annual.price ? 'col-md-4' : 'col-md-6'" *ngIf="(soloProfessionalPlan.annual && soloProfessionalPlan.annual.price) || (soloProfessionalPlan.monthly && soloProfessionalPlan.monthly.price)">

                <sync-plan-details [plan]="soloProfessionalPlan" [on-upgrade]="onUpgrade" [view-term]="viewTerm" [is-current-plan]="currentTrialPlan == soloProfessionalPlan"></sync-plan-details>

            </div>
        </ng-container>

        <ng-container *ngIf="view == 'teams'">
            <div class="col-sm-6" *ngIf="(teamsStandardPlan.annual && teamsStandardPlan.annual.price) || (teamsStandardPlan.monthly && teamsStandardPlan.monthly.price)">

                <sync-plan-details [plan]="teamsStandardPlan" 
                    [teams-qty]="teamsQty" [user-limit]="userLimit" [on-upgrade]="onUpgrade" [view-term]="viewTerm" [is-current-plan]="currentTrialPlan == teamsStandardPlan"></sync-plan-details>

            </div>


            <div class="col-sm-6" *ngIf="(teamsUnlimited.annual && teamsUnlimited.annual.price) || (teamsUnlimited.monthly && teamsUnlimited.monthly.price)">

                <sync-plan-details [plan]="teamsUnlimited" 
                    [teams-qty]="teamsQty" [user-limit]="userLimit" [on-upgrade]="onUpgrade" [view-term]="viewTerm" [is-current-plan]="currentTrialPlan == teamsUnlimited"></sync-plan-details>

            </div>
        </ng-container>
        <div class="col-sm-12" style="padding-top:32px;"
            *ngIf="!personalPlan.annual && !soloBasicPlan.annual && !soloBasicPlan.monthly && !soloProfessionalPlan.annual && !soloProfessionalPlan.monthly && !teamsStandardPlan.annual && !teamsStandardPlan.monthly && !teamsUnlimited.annual && !teamsUnlimited.monthly">
            <div class="col-sm-8" style="border: 1px solid #e9ecef; padding:16px;">
                <div style="font-size:26px;padding-bottom:16px;">
                    Add more users to your current Business Pro plan:
                    <button (click)="addUsers()" style="background-color:#00adef;border-color:#00adef;"
                        class="btn btn-primary btn-lg">Click here to add users</button>
                </div>

                <div style="font-size:26px;padding-bottom:16px;">
                    Contact our billing team to upgrade your plan:
                    <a style="background-color:#00adef;border-color:#00adef;" class="btn btn-primary btn-lg"
                        href="https://www.sync.com/support" rel="noopener">Click here to contact us</a>
                </div>
            </div>
        </div>
    </div>
    <!-- faq -->

    <div class="row" *ngIf="!spinner && !unpaidInvoices && !cancelInFuture && !teamsSubUser">
        <div class="col-sm-2"></div>
        <div class="col-sm-8" style="font-size:18px;margin-top:32px;">

            <h3>On the Team plan, what constitutes a user?</h3>
            <p>A user is an employee on your team with their own email address. You'll get the ability to create
                and
                manage separate Sync accounts for each user, manage passwords, and control which folders and
                files each
                user can access. External people you share with do not count as users (you get unlimited
                external
                sharing on the Solo and Team plans).</p>
            <h3>Are upgrades prorated?</h3>
            <p>Yes, you will be credited back the remaining balance on your existing premium plan, towards any
                new plan.
            </p>
            <h3>Which plans provide Business compliance?</h3>
            <p>The Pro Solo Professional, Pro Teams Standard, and Pro Teams Unlimited plans include HIPAA compliance for US healthcare providers. 
                All Pro Solo and Pro Teams plans include EU GDPR, Canada PIPEDA, and SOC 2/3. 
                When setting up Sync with multiple employees the Pro Teams plans include an Administrator account and advanced multi-user access controls.
            </p>
            <h3>What methods of payment do you accept?</h3>
            <p>Sync accepts Visa, MasterCard, American Express, PayPal and Bitcoin. There are no hidden costs or
                setup
                fees, and you can upgrade or change plans at any time (upgrades are always pro-rated for fair
                billing).
            </p>
            <h3>Questions?</h3>
            <p><a class="syncblue" href="https://www.sync.com/support/" rel="noopener" target="_blank"><b>Contact
                        us</b></a> at any time. We're real people ready to help you with any sales or technical
                support
                related questions you may have. And we love talking about security, privacy, and encryption too.
            </p>

        </div>
        <div class="col-sm-2"></div>
    </div>
</div>

<!-- end product selection row -->