import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

// Utility function to safely stringify objects, avoiding errors caused by cyclic references
function safeStringify(obj: any): any {
  const seen = new WeakSet(); // To track circular references
  return JSON.parse(
    JSON.stringify(obj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return; // Skip circular references to prevent cyclic value errors
        }
        seen.add(value); // Add the object to the set
      }
      return value; // Return non-circular values as-is
    })
  );
}

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  constructor(private userService: UserService) {
    // Do not initialize Sentry in development mode
    if (!environment.isDev) {
      this.initSentry();
    }
  }

  // Initialize Sentry with configuration and custom beforeSend logic
  private initSentry() {
    Sentry.init({
      environment: environment.production ? 'production' : 'staging',
      dsn: 'https://b2f86663e750e4d983cc2f812f75272a@o4507827516342272.ingest.de.sentry.io/4508003052027984',
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ['localhost', /^\//],
        }),
      ],

      tracesSampleRate: 1.0, //  Capture 100% of the transactions

      ignoreErrors: [
        'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
        'Converting circular structure to JSON',
        'JSON.stringify cannot serialize cyclic structures',
        'empty passwd encrypted string'
      ],

      // Preprocess events before sending to Sentry
      beforeSend: (event: Sentry.Event, hint?: Sentry.EventHint) => {
        const is_disableanalytics = this.userService.get('is_disableanalytics'); // Get the user from the event
        // Not sending events if user has disabled analytics
        if (is_disableanalytics) {
          return null; // Discard the event
        }

        try {
          if (hint && hint.originalException) {
            // Handle cases where the original exception is an instance of Error
            if (hint.originalException instanceof Error) {
              // Extract the first 10 enumerable properties of the error to avoid large payloads
              const errorProperties = Object.keys(hint.originalException)
                .slice(0, 10)
                .reduce((acc: Record<string, any>, key: string) => {
                  acc[key] = safeStringify(hint.originalException[key]); // Safely stringify property values
                  return acc;
                }, {});

              // Add extracted error properties and stack trace to event's extra context
              event.extra = event.extra || {};
              event.extra.errorProperties = errorProperties;

              if (hint.originalException.stack) {
                event.extra.stack = hint.originalException.stack; // Attach stack trace if available
              }
            } else {
              // Handle non-Error objects by serializing the raw exception
              event.extra = {
                ...event.extra,
                rawOriginalException: safeStringify(hint.originalException),
              };
            }
          }
        } catch (error) {
          console.warn(
            '[sentry] Failed to process original exception or extras',
            error,
            hint && hint.originalException
          );
        }

        // attaching user id to sentry error
        event.user = this.userService.get('id');

        return event; // Return the modified event for sending to Sentry
      },
    });
  }

  public logError(error: any, additionalContext: any) {
    if (!environment.isDev) {
      Sentry.captureException(error, (scope: Sentry.Scope) => {
        scope.setExtra('context', additionalContext);
        return scope;
      });
    }
  }
}
