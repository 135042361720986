<div class="modal-header">
    <button type="button" class="close"(click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title" *ngIf="openState == 1">User settings for {{child.email}}</h4>
    <h4 class="modal-title" *ngIf="openState == 2">Remove user {{child.email}}?</h4>
</div>
<div class="modal-body" *ngIf="openState == 1">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row" *ngIf="child.has_password_reset">
        <div class="col-sm-4">Change password:</div>
        <div class="col-sm-8">
            <div>
                Enter new password for {{child.email}}:
                <input
                    [(ngModel)]="pass1"
                    type="password"
                    class="form-control"
                    placeholder="Enter new password"
                    id="text-mu-passreset-newpass1"
                    syncShowHidePassword
                    identity="subuserpasschange"
                >
            </div>
            <sync-complex-password [password]="pass1"></sync-complex-password>
            <br>
            <div>
                Confirm new password for {{child.email}}:
                <input
                    [(ngModel)]="pass2"
                    type="password"
                    class="form-control"
                    placeholder="Confirm new password"
                    id="text-mu-passreset-newpass2"
                    syncShowHidePassword
                    identity="subuserpasschange"
                >
            </div>
            <br>
            <div>
                Enter your password ({{user.email}}):
                <input
                    [(ngModel)]="parentPass"
                    type="password"
                    class="form-control"
                    placeholder="Enter your password"
                    id="text-mu-passreset-parent-password"
                    syncShowHidePassword
                    identity="subuserpasschange"
                >
            </div>
            <div class="subtext">(this is used for security verification only)</div>
            
        </div>
    </div>
            
    <hr *ngIf="child.has_password_reset" />
            
    <div class="row">
        <div class="col-sm-4">Purge files:</div>
        <div class="col-sm-8">
            <div class="radio radio-top">
                <label>
                    <input
                        type="radio"
                        name="disablePurge"
                        id="multiuser-child-purge-disabled"
                        value="1"
                        [(ngModel)]="disablePurge"
                    />
                    Do not allow user to permanently delete files
                </label>
            </div>
            <div class="radio radio-bottom">
                <label>
                    <input
                        type="radio"
                        name="disablePurge"
                        id="multiuser-child-purge-enabled"
                        value="0"
                        [(ngModel)]="disablePurge"
                    />
                    Allow user to permanently delete files
                </label>
            </div>
        </div>
    </div>
</div>
<div class="modal-body" *ngIf="openState == 2">
    <sync-error [errcode]="errcode"></sync-error>

    <div class="row">
        <div class="col-sm-12">
            <p>
                By default the Sync account {{child.email}} will remain active
                and usable, however, upon removal the account will be
                downgraded to a free plan.
            </p>
              
            <p>Note: you will also need to remove this user from any
                shares you've created. We recommend you do this step
                first, prior to removal, from the
                <a routerLink="/shares" (click)="activeModal.close()">shares tab</a>.
            </p>
              
        </div>
    </div>
    
    <hr>
    <div class="row">
        <div class="col-sm-4">Purge account?</div>
        <div class="col-sm-8">
            <div class="radio radio-top">
                <label>
                    <input
                        type="radio"
                        name="rmPurge"
                        id="opt-multiuser-rm-purge-false"
                        value="0"
                        [(ngModel)]="rmPurge"
                    >
                No, account will remain active as a free account.
                </label>
            </div>

            <div class="radio radio-bottom">
                <label>
                    <input
                        type="radio"
                        name="rmPurge"
                        id="opt-multiuser-rm-purge-true"
                        value="1"
                        [(ngModel)]="rmPurge"
                    >
                    Yes, account will be destroyed, and all user data will be purged. This cannot be undone.
                </label>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer" *ngIf="openState == 1">
    <button type="button" class="btn btn-default pull-left" (click)="openState = 2">Remove user</button>
    <button
        type="button"
        class="btn btn-primary"
        (click)="saveSettings()"
        [disabled]="spinner"
    >
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
        Save settings
    </button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Cancel</button>
</div>

<div class="modal-footer" *ngIf="openState == 2">
    <button
        type="button"
        class="btn btn-primary"
        [disabled]="spinner"
        (click)="removeUser()"
    >
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
        Remove user
    </button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Cancel</button>
</div>