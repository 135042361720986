import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrCode, MultiAdminUserData, OrderSessionNewApiOutput, Role, User, AlertMessage, AlertMessageType, PermissionActions, BlendEvent } from '../../shared/models';
import { ProvisionService } from '../../account/services/provision.service';
import { ApiService } from '../../core/api.service';
import { UserService } from '../../core/user.service';
import { LoggerService } from '../../core/logger.service';
import { environment } from '../../../environments/environment';
import { SyncCookieService } from '../../core/sync-cookie.service';
import { MultiAdminService } from '../../core/multiadmin.service';
import { Router } from '@angular/router';
import { ZuoraAccountService } from '../../account/services/zuora-account.service';
import { BlendService } from 'src/app/shared/services/blend.service';
import { SkuService } from 'src/app/shared/services/sku.service';

@Component({
    selector: 'sync-dialog-multiadminuser-add',
    templateUrl: './dialog-multiadminuser-add.component.html'
})
export class DialogMultiAdminuserAddComponent implements OnInit {
    @ViewChild('csvReader') csvReader: any;

    @Input() hasEmptySlots: boolean;
    @Input() rolesList: Role[];
    @Input() plan_user_limit: number;
    @Input() plan_product_id: string;
    @Input() plan_rate_plan_id: string;
    @Input() plan_rate_plan_charge_id: string;
    @Input() remainingUsersAmount: number;
    @Input() multiadminService: MultiAdminService;
    @Input() openState: number;
    @Input() addAction: 'existing' | 'newusers' | 'order' | 'upgrade';
    @Input() currentUser: MultiAdminUserData;
    @Input() currentList: MultiAdminUserData[] = [];

    public errcode: ErrCode;
    public spinner = false;

    public email: string;
    public role: string;
    public emailField = '';
    public emails: string[] = [];
    public user: User;
    public selectedFilename = '';
    public users: object[] = [];
    public refcode: string;
    public password: string;
    public passreset = '1';
    public purgefiles = '0';
    public permissionActions = PermissionActions;
    public userLimit: number[];
    public sku = '';

    public addUserAmount = '1';

    public extraUsers = false;

    public alertMsg: AlertMessage;

    public existingEmailsInOtherTeam: string[] = [];
    public existingEmailsInCurrentTeam: string[] = [];

    public isSeatsNeeded: boolean;

    public isEmailVerified: boolean;
    public isUserInvited = false;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private syncCookie: SyncCookieService,
        private log: LoggerService,
        private provision: ProvisionService,
        private userService: UserService,
        private skuService: SkuService,
        private router: Router,
        private zuora: ZuoraAccountService,
        private blendService: BlendService,
    ) { }

    async ngOnInit() {
        this.spinner = false;
        this.role = this.rolesList && this.rolesList.length > 0 ? this.rolesList[0].name : this.userService.DEFAULT_ROLE;
        if (this.openState == 4) {
            this.addAction = 'newusers';
        } else if (this.openState == 3) {
            if (this.userService.get('is_on_trial')) {
                this.showPurchasePlan();
            } else if (this.userService.get('plan_sku').toUpperCase().includes('BFCM2024')) {
                this.addAction = 'upgrade';
                this.openState = 6;
            } else {
                this.addAction = 'order';
            }
        } else if (this.openState == 5) {
            this.addAction = 'upgrade';
        } else {
            this.addAction = 'newusers';
            this.openState = 1;
        }
        this.isEmailVerified = this.userService.get('is_verified');
        this.sku = await this.skuService.getSku();
        this.userLimit = Array(300 - this.plan_user_limit).fill(0).map((x, i) => i + 1);
    }

    public checkPermission(permName: PermissionActions): boolean {
        return this.userService.checkPermission(permName);
    }

    /**
     * Bound to add user button click
     */
    public addUser() {
        if (this.addAction == 'newusers') {
            this.addMultipleUsers();
        } else if (this.addAction == 'order') {
            this.orderAddUser();
        } else {
            this.errcode = new ErrCode(8220);
        }
    }

    /**
     * If the input is valid, the add button will be enabled.
     */
    public inputValid() {
        if (this.addAction == 'newusers') {
            return (this.email && this.provision.validEmail(this.email) && this.password);
        } else if (this.addAction == 'order') {
            return true;
        } else {
            return false;
        }
    }

    public goNext() {
        if (this.addAction == 'newusers') {
            this.openState = 4;
        } else if (this.addAction == 'order') {
            this.openState = 3;
        } else {
            return false;
        }
    }

    public showPurchaseUsers() {
        if (this.userService.get('is_on_trial')) {
            this.showPurchasePlan();
        } else if (this.userService.get('plan_sku').toUpperCase().includes('BFCM2024')) {
            this.addAction = 'upgrade';
            this.openState = 6;
        } else {
            this.addAction = 'order';
            this.openState = 3;
        }
    }

    public showPurchasePlan() {
        this.addAction = 'upgrade';
        this.openState = 5;
    }

    public redirectToUpgrade() {
        this.router.navigate(['/account/upgrade']);
        this.activeModal.close();

    }

    private async orderAddUser() {
        const additionalUsers: number = parseInt(this.addUserAmount, 10);
        if (this.userService.checkPermission(this.permissionActions.ADD_EDIT_USERS)) {

            const p = this.plan_product_id;
            const rp = this.plan_rate_plan_id;
            const rpc = this.plan_rate_plan_charge_id;
            const qty = this.plan_user_limit;
            const isMultiNew = this.userService.get('is_multi_new');
            const isMultiAdmin = this.userService.get('is_multi_admin');
            const zprofile = await this.zuora.getProfile();

            if ((isMultiNew || (!isMultiNew && isMultiAdmin)) && zprofile && zprofile.balance <= 0 && zprofile.unpaidInvoiceCount >= 0) {
                try {
                    const result = await this.api.execute<OrderSessionNewApiOutput>('ordersessionnew', {});
                    this.syncCookie.setOrderSessionId(result.sessionId);
                    const url = environment.ordershost
                        + '/upgrade/'
                        + p
                        + '?rp=' + rp
                        + '&rpc=' + rpc
                        + '&qty=' + (qty + additionalUsers);
                    this.log.info(`Sending user to upgrade ${url}`);
                    window.location.href = url;
                } catch (ex) {
                    this.errcode = new ErrCode(8550);
                    this.log.error(`Error creating order session to add user ${JSON.stringify(ex)}`);
                }
            } else {
                this.router.navigate(['/account/upgrade']);
                this.activeModal.close();
            }
        } else {
            this.errcode = new ErrCode(8505);
        }
    }

    private sanitizeEmail(email: string) {
        return email.toLowerCase().trim();
    }

    public uploadListener($event: any): void {
        let files = $event.srcElement.files;
        if (!files) {
            files = $event.dataTransfer.files;
        }
        if (this.isValidCSVFile(files[0])) {
            const reader = new FileReader();
            reader.readAsText(files[0]);
            this.selectedFilename = files[0].name;
            reader.onload = () => {
                this.errcode = null;
                this.alertMsg = null;
                const csvData = reader.result;
                const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
                this.users = [];
                const header = csvRecordsArray[0];
                const headerProps = header.split(',');
                if (headerProps.length < 2 || headerProps[0].trim() != 'EmailAddress' || headerProps[1].trim() != 'RoleName') {
                    this.handleCSVErrors(`Header text is incorrect or missing.`);
                    return;
                }
                const csvUsers = [];
                const emptyCells = [];
                const invalidEmails = [];
                const invalidRoles = [];
                for (let i = 1; i < csvRecordsArray.length; i++) {
                    const props = csvRecordsArray[i];
                    const p = props.split(',');
                    if (props != '') {
                        const cellEmail = p[0].trim();
                        const cellRole = p[1].trim() || this.userService.DEFAULT_ROLE;
                        const selectedRole = this.rolesList.filter(role => role.name.toLowerCase().trim() === cellRole.toLowerCase());
                        if (!cellEmail) {
                            emptyCells.push(i + 1);
                        } else {
                            if (!this.provision.validEmail(cellEmail)) {
                                invalidEmails.push(i + 1);
                            }

                            if (selectedRole.length === 0 && !invalidRoles.includes(cellRole)) {
                                invalidRoles.push(cellRole);
                            }
                        }
                        const role = selectedRole.length ? selectedRole[0].name : cellRole;
                        csvUsers.push({email: cellEmail, role});
                    }
                }

                if (csvUsers.length > this.plan_user_limit) {
                    this.handleCSVErrors(`Your plan includes only ${this.plan_user_limit} users, the CSV includes ${csvUsers.length} users`);
                    return;
                }

                if (csvUsers.length > this.remainingUsersAmount) {
                    this.handleCSVErrors(`You only have ${this.remainingUsersAmount} user${this.remainingUsersAmount > 1 ? 's' : ''} available, the CSV includes ${csvUsers.length} new users`);
                    return;
                }

                if (emptyCells.length) {
                    if (emptyCells.length > 1) {
                        this.handleCSVErrors(`row ${emptyCells.slice(0, -1).join(', ') + ' and ' + emptyCells.slice(-1)} are missing email`);
                    } else {
                        this.handleCSVErrors(`row ${emptyCells.toString()} is missing email`);
                    }
                    return;
                }

                if (invalidEmails.length) {
                    if (invalidEmails.length > 1) {
                        this.handleCSVErrors(`The email addresses on rows ${invalidEmails.slice(0, -1).join(', ') + ' and ' + invalidEmails.slice(-1)} are invalid.`);
                    } else {
                        this.handleCSVErrors(`The email address on row ${invalidEmails.toString()} is invalid.`);
                    }
                    return;
                }

                if (invalidRoles.length) {
                    if (invalidRoles.length > 1) {
                        this.handleCSVErrors(`${invalidRoles.slice(0, -1).join(', ') + ' and ' + invalidRoles.slice(-1)} do not exist as roles.`);
                    } else {
                        this.handleCSVErrors(`${invalidRoles.toString()} does not exist as a role.`);
                    }
                    return;
                }

                for (let i = 0; i < csvUsers.length; i++) {
                    this.addUsers(csvUsers[i]);
                }

            };
            reader.onerror = () => {
                this.handleCSVErrors(`Error is occured while reading file.`);
            };
        } else {
            this.alertMsg = new AlertMessage(`Please import valid .csv file.`, AlertMessageType.ERROR);
            this.fileReset();
        }
    }

    private handleFileUploadErrors(code: number, msg: string) {
        this.errcode = new ErrCode(code, msg);
        this.fileReset();
        return false;
    }

    private handleCSVErrors (msg: string) {
        this.alertMsg = new AlertMessage(msg, AlertMessageType.ERROR);
    }

    private isValidCSVFile(file: any) {
        return file && file.name.endsWith('.csv');
    }

    public fileReset() {
        if (this.csvReader && this.csvReader.nativeElement && this.csvReader.nativeElement.value) {
            this.csvReader.nativeElement.value = '';
        }
        this.selectedFilename = '';
        this.users = [];
    }

    private parseEmails() {
        this.users = [];
        this.emails.forEach(email => {
            if (email !== '') {
                this.addUsers({ email: email, role: this.role });
            }
        });
    }

    public addEmails() {
        this.emailField.split(',').forEach(email => {
            if (email.trim() !== '') {
                this.emails.push(email.trim());
            }
        });
        this.emailField = '';
    }

    public removeEmail(email: string) {
        const index = this.emails.indexOf(email, 0);
        if (index > -1) {
            this.emails.splice(index, 1);
        }
    }

    private addUsers(user) {
        this.users.push({
            usernameB64: btoa(this.sanitizeEmail(user.email)),
            password: '',
            role: user.role,
            signup: {
                encPassPriv: '',
                encPassMeta: '',
                enableReset: 0,
                encSyncRootName: '',
                lastNameB64: '',
                firstNameB64: '',
                pubKey: '',
                encPrivKey: '',
                encMetaKey: '',
                encShareKey: '',
                encPassword: ''
            }
        });
    }

    private async getUsersList() {
        try {
            const result = await this.multiadminService.getUserList();
            this.plan_user_limit = result.user_limit;
            this.remainingUsersAmount = this.plan_user_limit - result.users.length;
            // if user has multiple sessions open, this makes sure fetch new data before addind new users
        } catch (error) {
            this.log.error(error);
            // keeping it non fatal as we don't want to fail the add users
        }
    }

    private async addMultipleUsers() {
        this.spinner = true;
        await this.getUsersList();
        this.errcode = null;
        this.alertMsg = null;
        this.isSeatsNeeded = false;
        if (this.selectedFilename === '') {
            this.parseEmails();
        }
        if (this.users.length === 0) {
            this.alertMsg = new AlertMessage('Please select a csv file or add user email(s) in textbox.', AlertMessageType.ERROR);
            this.spinner = false;
            return;
        } else if (this.users.length > this.plan_user_limit) {
            this.alertMsg = new AlertMessage(`Number of users to invite exceeds the allowed user for current plan, Your plan includes only ${this.plan_user_limit} users.`, AlertMessageType.ERROR);
            this.spinner = false;
            return;
        } else if (this.users.length > this.remainingUsersAmount) {
            this.isSeatsNeeded = true;
            this.spinner = false;
            return;
        }
        const invalidEmails = [];
        this.users.forEach((user: any) => {
            const email = atob(user.usernameB64);
            if (!this.provision.validEmail(email)) {
                invalidEmails.push(email);
            }
        });
        if (invalidEmails.length) {
            if (invalidEmails.length > 1) {
                this.alertMsg = new AlertMessage(`The email addresses ${invalidEmails.slice(0, -1).join(', ') + ' and ' + invalidEmails.slice(-1)} are invalid.`, AlertMessageType.ERROR);
            } else {
                this.alertMsg = new AlertMessage(`The email address ${invalidEmails.toString()} is invalid.`, AlertMessageType.ERROR);
            }
            this.fileReset();
            this.spinner = false;
            return;
        }
        try {
            const result = await this.multiadminService.checkExistingUsers({
                'teamId': this.userService.get('uid'),
                'users': this.users
            });
            if (result.usersExisting.length) {

                //finding users which are existing in current team or another team
                this.existingEmailsInCurrentTeam = [];
                this.existingEmailsInOtherTeam = [];
                result.usersExisting.forEach(user => {
                    if (this.currentList.some(item => item.email === user.usernameB64) || user.usernameB64 === this.currentUser.email) {
                        this.existingEmailsInCurrentTeam.push(user.usernameB64);
                    } else {
                        this.existingEmailsInOtherTeam.push(user.usernameB64);
                    }
                });

                if (this.existingEmailsInOtherTeam.length && this.existingEmailsInCurrentTeam.length) {
                    const allexistingUsersEmails = this.existingEmailsInOtherTeam.concat(this.existingEmailsInCurrentTeam);
                    if (allexistingUsersEmails.length > 9) {
                        this.alertMsg = new AlertMessage(`${allexistingUsersEmails.length} users are part of current/another team and cannot be invited.`, AlertMessageType.ERROR);
                    } else {
                        this.alertMsg = new AlertMessage(`${allexistingUsersEmails.slice(0, -1).join(', ') + ' and ' + allexistingUsersEmails.slice(-1)} are part of current/another team and cannot be invited.`, AlertMessageType.ERROR);
                    }
                } else if (this.existingEmailsInOtherTeam.length) {
                    if (this.existingEmailsInOtherTeam.length > 9) {
                        this.alertMsg = new AlertMessage(`${this.existingEmailsInOtherTeam.length} users are part of another team and cannot be invited.`, AlertMessageType.ERROR);
                    } else if (this.existingEmailsInOtherTeam.length > 1) {
                        this.alertMsg = new AlertMessage(`${this.existingEmailsInOtherTeam.slice(0, -1).join(', ') + ' and ' + this.existingEmailsInOtherTeam.slice(-1)} are part of another team and cannot be invited.`, AlertMessageType.ERROR);
                    } else {
                        this.alertMsg = new AlertMessage(`${this.existingEmailsInOtherTeam.toString()} is part of another team and cannot be invited.`, AlertMessageType.ERROR);
                    }
                } else {
                    if (this.existingEmailsInCurrentTeam.length > 9) {
                        this.alertMsg = new AlertMessage(`${this.existingEmailsInCurrentTeam.length} users already exist.`, AlertMessageType.ERROR);
                    } else if (this.existingEmailsInCurrentTeam.length > 1) {
                        this.alertMsg = new AlertMessage(`${this.existingEmailsInCurrentTeam.slice(0, -1).join(', ') + ' and ' + this.existingEmailsInCurrentTeam.slice(-1)} are already exist.`, AlertMessageType.ERROR);
                    } else {
                        this.alertMsg = new AlertMessage(`${this.existingEmailsInCurrentTeam.toString()} already exist.`, AlertMessageType.ERROR);
                    }
                }

                if (result.usersExisting.length < this.users.length) {
                    this.extraUsers = true;
                }
            } else {
                await this.proceedAddUser();
            }
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
    }

    async proceedAddUser() {
        this.errcode = null;
        this.alertMsg = null;
        this.spinner = true;
        try {
            await this.multiadminService.addMultipleUsers({
                'teamId': this.userService.get('uid'),
                'users': this.users
            });

            const addMethod = this.selectedFilename == '' ? 'Manual' : 'CSV';
            const existingUserCount = this.existingEmailsInCurrentTeam.length + this.existingEmailsInOtherTeam.length;
            const addedUserCount = this.users.length - existingUserCount;
            if (existingUserCount) {
                this.alertMsg = new AlertMessage(`${addedUserCount} user${addedUserCount > 1 ? 's were' : ' was'} added, ${existingUserCount} user${existingUserCount > 1 ? 's' : ''} already exist.`);
            } else {
                this.alertMsg = new AlertMessage(`${addedUserCount} user${addedUserCount > 1 ? 's were' : ' was'} added.`);
            }
            this.isUserInvited = true;
            this.fileReset();
            this.emails = [];
            this.blendService.track(BlendEvent.SUB_USER_ADDED, { num_users: addedUserCount, sku: this.sku, method: addMethod });
            setTimeout(() => {
                this.activeModal.close();
            }, 5000);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
    }

    resetFields() {
        this.errcode = null;
        this.extraUsers = false;
        this.emailField = '';
        this.emails = [];
        this.fileReset();
        this.alertMsg = null;
    }

    onDrop(event) {
        event.preventDefault();
        this.uploadListener(event);
    }
    onDragOver(event) {
        event.stopPropagation();
        event.preventDefault();
    }
}
